<template>
  <b-overlay
    :show="showLoading"
    variant="dark"
    :opacity="0.25"
    rounded="sm"
  >
    <b-card
      no-body
      class="p-3"
    >
      <b-row>
        <b-col
          cols="12"
        >
          <validation-observer ref="formRef">
            <b-form @submit.prevent>
              <b-row>
                <!-- Transaction No Field -->
                <b-col cols="12" md="4">
                  <b-form-group
                    :label="$t('apps.transactionsCashbankRekon.singular.transactionCode')"
                    label-for="code"
                  >
                    <b-form-input
                      id="code"
                      v-model="form.code"
                      placeholder="CI-2021020001"
                      size="sm"
                      :disabled="actions.isPreview"
                    />
                  </b-form-group>
                </b-col>
                <!-- Cashbank Account Field -->
                <b-col cols="12" md="4">
                  <b-form-group
                    :label="$t(`apps.transactionsCashbankRekon.singular.cashbank`)"
                    label-for="cashbank-account"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="cashbank"
                      rules="required"
                    >
                      <v-select
                        v-if="companyActive.plan_id > 2"
                        id="cashbank-account"
                        ref="firstFocusSelectInput"
                        v-model="form.cashbankId"
                        :options="LOV.cashbankCodeAccounts"
                        :reduce="field => field.id"
                        label="label"
                        class="select-size-sm"
                        :state="errors.length > 0 ? false:null"
                        :disabled="actions.isPreview"
                      />
                      <v-select
                        v-else
                        id="cashbank-account"
                        ref="firstFocusSelectInput"
                        v-model="form.accountId"
                        :options="LOV.cashbankAccounts"
                        :reduce="field => field.id"
                        label="label"
                        class="select-size-sm"
                        :state="errors.length > 0 ? false:null"
                        :disabled="actions.isPreview"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Account Name Field -->
                <b-col cols="12" md="4" v-show="form.accountGeneral">
                  <b-form-group
                    :label="$t('apps.transactionsCashbankRekon.singular.accountName')"
                    label-for="accountName"
                  >
                    <b-form-input
                      id="accountName"
                      v-model="form.accountName"
                      placeholder="Account Name"
                      :disabled="actions.isPreview"
                    />
                  </b-form-group>
                </b-col>
                <!-- Date Field -->
                <b-col cols="12" md="4">
                  <b-form-group
                    :label="$t('apps.transactionsCashbankRekon.singular.date')"
                    label-for="date"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="date"
                      rules="required"
                    >
                      <flat-pickr
                        id="date"
                        :config="dateFormat"
                        v-model="form.date"
                        :state="errors.length > 0 ? false:null"
                        :disabled="actions.isPreview"
                        class="form-control form-control-sm"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Tags -->
                <b-col cols="12" md="8">
                  <b-form-group
                    :label="$t('globalSingular.tags')"
                    label-for="tags"
                  >
                    <v-select
                      v-model="form.tags"
                      id="tags"
                      class="select-size-sm"
                      label="name"
                      multiple
                      :options="LOV.tags"
                      :reduce="field => field.id"
                      :create-option="field => ({id: Date.now(), name: field})"
                      @option:created="pushTags"
                      :searchable="!actions.isPreview"
                      :selectable="option => !actions.isPreview"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-table
                id="refFormTable"
                ref="refFormTable"
                fixed
                no-provider-paging
                no-provider-filtering
                :fields="tableColumns"
                :items="form.details"
                primary-key="id"
                show-empty
                class="mt-2 position-relative"
              >
                <!-- Custom Header Column -->
                <template #head(actions)>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    class="mx-auto"
                  />
                </template>
                <!-- Styling width -->

                <!-- Column: Date -->
                <template #cell(date)="{ index }">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="date"
                      rules="required"
                      :vid="`detail-${index}`"
                    >
                      <flat-pickr
                        id="date"
                        :config="dateFormat"
                        v-model="form.details[index].date"
                        :state="errors.length > 0 ? false:null"
                        :disabled="actions.isPreview"
                        class="form-control form-control-sm"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </template>

                <!-- Column: Transaction Code -->
                <template #cell(code)="{ index }">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="code"
                      rules="required"
                      :vid="`detail-${index}`"
                    >
                      <b-form-input
                        id="code"
                        v-model="form.details[index].code"
                        placeholder="CI-2021020001"
                        size="sm"
                        :disabled="actions.isPreview"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </template>

                <!-- Column: Account -->
                <template #cell(accountId)="{ index }">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="account"
                      rules="required"
                      :vid="`detail-${index}`"
                    >
                      <v-select
                        id="account-type"
                        v-model="form.details[index].accountId"
                        :options="LOV.accounts"
                        :reduce="field => field.id"
                        label="label"
                        class="select-size-sm"
                        @option:selected="activityChange(index,LOV.accounts,form.details[index].accountId)"
                        :state="errors.length > 0 ? false:null"
                        :disabled="actions.isPreview"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </template>

                <!-- Column: Debit -->
                <template #cell(debit)="{ index }">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="debitAmount"
                      :rules="!form.details[index].creditAmount ? 'required' : ''"
                      :vid="`detail-${index}`"
                    >
                      <cleave
                        :key="index"
                        v-model.number="form.details[index].debitAmount"
                        :state="errors.length > 0 ? false:null"
                        class="text-right form-control form-control-sm"
                        :options="numeric"
                        :disabled="form.details[index].creditAmount || actions.isPreview"
                        placeholder="10,000"
                        @blur="sumAmount"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </template>

                <!-- Column: Credit -->
                <template #cell(credit)="{ index }">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="creditAmount"
                      :rules="!form.details[index].debitAmount ? 'required' : ''"
                      :vid="`detail-${index}`"
                    >
                      <cleave
                        :key="index"
                        v-model.number="form.details[index].creditAmount"
                        :state="errors.length > 0 ? false:null"
                        class="text-right form-control form-control-sm"
                        :options="numeric"
                        :disabled="form.details[index].debitAmount || actions.isPreview"
                        placeholder="10,000"
                        @blur="sumAmount"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </template>

                <!-- Column: Actions -->
                <template #cell(actions)="{ index }">
                  <b-button
                    variant="flat-danger"
                    class="btn-icon invoice-edit-input"
                    :disabled="form.details.length <= 1"
                    @click="removeRow(index)"
                  >
                    <feather-icon icon="MinusIcon" />
                  </b-button>
                </template>
                <!-- Costum Footer -->
                <template #custom-foot="{}">
                  <b-tr>
                    <b-td colspan="3" class="pl-0">
                      <b-button
                        v-if="!actions.isPreview"
                        variant="outline-primary"
                        block
                        @click="addRow"
                      >
                        <feather-icon
                          icon="PlusIcon"
                        />
                        {{ $t('globalActions.addMore') }}
                      </b-button>
                    </b-td>
                    <b-td class="text-right">
                      <div class="mb-0 h4 font-weight-bolder">{{ $t('globalSingular.debit') }} : {{ totalAmount.debit }}</div>
                    </b-td>
                    <b-td class="text-right">
                      <div class="mb-0 h4 font-weight-bolder">{{ $t('globalSingular.credit') }} : {{ totalAmount.credit }}</div>
                    </b-td>
                    <b-td>&nbsp;</b-td>
                  </b-tr>
                </template>

                <!-- Column: Description -->
                <template v-slot:row-details="{ index }">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="description"
                      rules="required"
                      :vid="`detail-${index}`"
                    >
                      <b-form-input
                        v-model="form.details[index].remark"
                        :state="errors.length > 0 ? false:null"
                        :placeholder="!actions.isPreview ? $t('apps.transactionsCashbankRekon.placeholder.description') : null"
                        :disabled="actions.isPreview"
                        size="sm"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </template>
              </b-table>

              <!-- <b-card-body> -->
              <b-row>
                <!-- Description Field -->
                <b-col cols="12" md="8">
                  <b-form-group
                    :label="$t('apps.transactionsCashbankRekon.singular.notes')"
                    label-for="notes"
                  >
                    <b-form-textarea
                      id="notes"
                      v-model="form.remark"
                      rows="1"
                      :placeholder="!actions.isPreview ? $t('apps.transactionsCashbankRekon.placeholder.description') : null"
                      :disabled="actions.isPreview"
                      size="sm"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <!-- </b-card-body> -->

              <div class="mt-3 d-flex flex-sm-column flex-md-row justify-content-between">
                <b-button
                  class="mb-sm-1 mb-md-0"
                  :variant="actions.isPreview ? 'outline-secondary' : 'outline-primary'"
                  :to="{ name: 'apps-transactions-cashbank-rekon-list' }"
                >
                  <feather-icon
                    icon="ArrowLeftIcon"
                  />
                  {{ $t('globalActions.backToList') }}
                </b-button>

                <b-button
                  v-if="actions.isPreview"
                  class=" mb-sm-1 mb-md-0"
                  variant="outline-danger"
                  @click="handleDelete"
                >
                  <feather-icon
                    icon="Trash2Icon"
                  />
                  {{ $t('globalActions.delete') }}
                </b-button>

                <div v-if="!actions.isPreview">
                  <b-button
                    variant="primary"
                    type="submit"
                    @click="handleSubmit"
                  >
                    <feather-icon
                      icon="SaveIcon"
                    />
                    {{ actions.isEditMode ? $t('globalActions.update') : $t('globalActions.submit') }}
                  </b-button>
                </div>

                <b-button
                  v-else
                  variant="outline-primary"
                  @click="changeToEdit"
                >
                  <feather-icon
                    icon="EditIcon"
                  />
                  {{ $t('globalActions.changeToEdit') }}
                </b-button>
              </div>
            </b-form>
          </validation-observer>
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BTr,
  BTd,
  BFormGroup,
  BFormInput,
  BForm,
  BFormTextarea,
  BButton,
  BOverlay
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
import { ref, onMounted } from '@vue/composition-api'
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { formatCurrency, formatDatepicker } from '@/utils/formatter'

import useFormResource from '@/comp-functions/useFormResource'
import usePushTags from '@/comp-functions/utils/usePushTags'
import useLocalization from '@/comp-functions/utils/useLocalization'
import storestate from '@/store/index'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BTr,
    BTd,
    BFormGroup,
    BFormInput,
    BForm,
    BFormTextarea,
    BButton,
    BOverlay,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    flatPickr,
    Cleave
  },


  setup () {
    const {
      paramsId,
      formRef,
      firstFocusSelectInput,
      get,
      actions,
      changeToEdit,
      show,
      destroy,
      store
    } = useFormResource({
      url: 'transaction/cashbank/rekon',
      localeContextPath: 'apps.transactionsCashbankRekon.singular.transaction',
      redirectPathName: 'apps-transactions-cashbank-rekon',
    })


    const { $t } = useLocalization()
    let isNational = storestate.state.user.isNational
    const LOV = ref({
      cashbankAccounts: [],
      cashbankCodeAccounts: [],
      contacts: [],
      accounts: [],
      accountParents: [],
      tags: []
    })
    const showLoading = ref(false);
    const isCanceled = ref(false);


    const getCashbankAccount = async () => {
      LOV.value.cashbankAccounts = await get({ url: 'master/accounts/child?account_subtype_code=CASHBANK'})
    }


    const getCashbankCode = async () => {
      LOV.value.cashbankCodeAccounts = await get({ url: `value/mapping-cashbank-per-users?is_bank=true&is_national=${isNational}`})
    }


    const getAccounts = async () => {
      LOV.value.accounts = await get({ url: 'master/accounts/activity'})
    }


    const activityChange = async (index, option, selected) => {
      option.map(field => {
        if(field.id == selected){
          form.value.details[index].remark = field.description
        }
      })
    }


    const getContacts = async () => {
      LOV.value.contacts = await get({ url: 'value/contact/employee'})
    }


    const getTags = async () => {
      LOV.value.tags = await get({ url: 'value/tag'})
    }

    const tableColumns = ref([])
    const defaultDetailForm = {
      date: formatDatepicker(Date.now()),
      code: '',
      accountId: `${$t('apps.transactionsCashbankRekon.singular.activity')}`,
      remark: '',
      amount: null,
      debitAmount: null,
      creditAmount: null,
      _showDetails: true
    }
    const form = ref({
      accountId: '',
      cashbankId: '',
      contactId: '',
      code: '',
      amount: 0,
      debitAmount: 0,
      creditAmount: 0,
      date: formatDatepicker(Date.now()),
      tags: [],
      remark: '',
      accountGeneral: false,
      accountName: '',
      transactionTypeCode: 'RK',
      details: [
        // doing 'tricked' placeholder for vue-select accountId
        { ...defaultDetailForm }
      ]
    })
    const defaultForm = ref({})
    const companyActive = storestate.state.user.currentCompany
    const dateFormat = ref({
      dateFormat: 'd/m/Y',
      allowInput: true,
      disable: [
        function (date) {
          return (date.getTime() < (new Date(companyActive.closing_year,  companyActive.closing_month, 1).getTime()))
        }
      ]
    })
    const numeric = ref({
      numeral: true,
      numeralThousandsGroupStyle: 'thousand',
      numeralPositiveOnly: true,
    })
    const totalAmount = ref({
      debit: 0,
      credit: 0,
    })


    const sumAmount = () => {
      form.value.details.map((detail) => {
        detail.amount = detail.debitAmount ? (detail.debitAmount * 1) : (detail.creditAmount * -1)
      })

      form.value.debitAmount = form.value.details.reduce((accumulator, {debitAmount}) => accumulator + debitAmount, 0)
      form.value.creditAmount = form.value.details.reduce((accumulator, {creditAmount}) => accumulator + creditAmount, 0)
      totalAmount.value.debit = formatCurrency(form.value.debitAmount)
      totalAmount.value.credit = formatCurrency(form.value.creditAmount)
    }


    // will get data to show the preview
    const getDataPreview = async () => {
      const data = await show()

      form.value.details = data.details.map((field) => {
        return {
          id: field.id,
          date: field.date,
          code: field.code,
          accountId: field.details[0].activites_id,
          remark: field.remark,
          amount: 0,
          debitAmount: parseFloat(field.amount) > 0 ? parseFloat(field.amount) : 0,
          creditAmount: parseFloat(field.amount) < 0 ? parseFloat(field.amount) * -1 : 0,
          _showDetails: true
        }
      })

      form.value.accountId = data.account_id
      form.value.cashbankId = data.cashbank_id
      form.value.code = data.code
      form.value.date = data.date
      form.value.remark = data.remark
      form.value.tags = data.tags.map(tag => parseInt(tag))
    }


    // Add row details
    const addRow = () => {
      form.value.details.push({...defaultDetailForm})
    }


    // Remove row details
    const removeRow =  (index) => {
      if (form.value.details.length <= 1) return false
      form.value.details.splice(index, 1)
      sumAmount()
    }


    const resetForm = () => {
      Object.keys(defaultForm.value).map((key) => {
        form.value[key] = defaultForm.value[key]
      })
      form.value.details = [{...defaultDetailForm}]
    }


    const setDefaultForm = () => {
      // form.value.details.push({...defaultDetailForm})
      defaultForm.value = {...form.value}
    }


    onMounted(async() => {
      getCashbankAccount()
      getAccounts()
      getTags()
      getContacts()
      setDefaultForm()

      if (paramsId) {
        actions.value.isPreview = true
        await getDataPreview()
        await sumAmount()
      }

      if (companyActive.plan_id > 2) {
        getCashbankCode()
      }

      tableColumns.value = [
        { key: 'date', label: $t('apps.transactionsCashbankRekon.singular.date'), width: 'auto', thClass: 'bg-transparent pl-0', tdClass: 'align-top pl-0 pb-0' },
        { key: 'code', label: $t('apps.transactionsCashbankRekon.singular.code'), width: 'auto', thClass: 'bg-transparent pl-0', tdClass: 'align-top pl-0 pb-0' },
        { key: 'accountId', label: $t('apps.transactionsCashbankRekon.singular.activity'), width: 'auto', thClass: 'bg-transparent pl-0', tdClass: 'align-top pl-0 pb-0' },
        // { key: 'remark', label: $t('apps.transactionsCashbankRekon.singular.description'), width: 'auto', thClass: 'bg-transparent', tdClass: 'align-t pb-0op'},
        { key: 'debit', label: $t('apps.transactionsCashbankRekon.singular.debit'), width: '20%', thClass: 'bg-transparent text-right width-20-per', tdClass: 'align-top text-right pl-0 pb-0' },
        { key: 'credit', label: $t('apps.transactionsCashbankRekon.singular.credit'), width: '20%', thClass: 'bg-transparent text-right width-20-per', tdClass: 'align-top text-right pl-0 pb-0' },
        { key: 'actions', width: '12%', thClass: 'bg-transparent text-right width-5-per pr-0', tdClass: 'align-top text-right px-0 pb-0' },
      ]
    })


    const { pushTagsToAPI } = usePushTags()
    const pushTags = async newTag => {
      const id = await pushTagsToAPI(newTag)
      form.value.tags[form.value.tags.findIndex(val => val === newTag.id)] = id
    }


    return {
      companyActive,
      pushTags,
      required,
      LOV,
      showLoading,
      isCanceled,
      tableColumns,
      form,
      defaultForm,
      resetForm,
      addRow,
      removeRow,
      dateFormat,
      activityChange,
      totalAmount,
      sumAmount,
      numeric,
      paramsId,
      formRef,
      firstFocusSelectInput,
      get,
      actions,
      changeToEdit,
      show,
      destroy,
      store,
      isNational
    }
  },


  methods: {
    setFormData () {
      const tempForm = { ...this.form }
      delete tempForm.debitAmount
      delete tempForm.creditAmount
      tempForm.details = tempForm.details.map((detail) => {
        delete detail.debitAmount
        delete detail.creditAmount
        delete detail._showDetails
        return detail
      })

      return tempForm
    },


    async handleDelete () {
      this.showLoading = true
      try {
        await this.destroy(this.$swal)
        this.showLoading = false
      } catch (error) {
        this.showLoading = false
      }
    },


    handleContact (item) {
      let dataContact = this.LOV.contacts.filter(field => field.id === item)
      this.form.accountGeneral = (dataContact[0].is_general === true)?true:false
      this.form.accountName = ''
    },


    async handleSubmit () {
      this.showLoading = true
      try {
        await this.store({
          $swal: this.$swal,
          data: this.setFormData(),
        })
        this.showLoading = false
      } catch (error) {
        this.showLoading = false
      }
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#refFormTable .b-table-details td {
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
}
</style>
